<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col
      cols="12"
      lg="4"
      class="d-flex align-center date-picker-col">
      <date-range-picker v-model="filter.dates" />
    </v-col>
    <!-- <v-col
      cols="6"
      lg="2"
      class="d-flex align-center date-picker-col">
      <v-select
        v-model="filter.warehouse"
        :items="warehouses"
        :menu-props="{ offsetY: true }"
        item-value="id"
        item-text="name"
        label="Warehouses"
        class="select-warehouse"
        return-object
        auto-select-first
        outlined
        hide-details
        dense />
    </v-col> -->
    <v-col
      cols="6"
      lg="2"
      class="d-flex align-center date-picker-col">
      <v-select
        v-model="filter.state"
        :items="states"
        :menu-props="{ offsetY: true }"
        item-text="text"
        item-value="value"
        label="State"
        class="select-warehouse"
        auto-select-first
        outlined
        hide-details
        dense />
    </v-col>
    <v-col
      cols="12"
      lg="6"
      class="d-flex justify-end align-center date-picker-col">
      <search-box
        v-model="filter.search"
        @on-search="find()" />
    </v-col>
  </v-row>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import DateRangePicker from '@/components/DateRangePicker.vue'

export default {
  components: {
    DateRangePicker,
    SearchBox
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    warehouses: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      states: [
        {
          text: 'All',
          value: null
        },
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Solved',
          value: 'solved'
        },
        {
          text: 'Declined',
          value: 'declined'
        }
      ]
    }
  },
  computed: {
    filter: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    find () {
      this.$emit('on-find')
    }
  }
}
</script>
