var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded elevation-3 bg-white h-full",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"header"},[_vm._v("In Dispute Report")])])],1),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('idp-report-header',{attrs:{"warehouses":_vm.warehouses},on:{"on-find":function($event){return _vm.find()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"w-full d-flex justify-end align-center"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"secondary","disabled":!_vm.totalReports},on:{"click":function($event){return _vm.exportDataToCSV()}}},[_vm._v(" Export ("+_vm._s(_vm.totalReports)+") ")])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.reports,"hide-default-footer":"","no-data-text":"ไม่มีข้อมูลในช่วงเวลานี้","loading-text":"กรุณารอสักครู่..."},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDateFormat(item.createdAt))+" ")])]}},{key:"item.deliveryNoteId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.deliveryNoteId)+" ")])]}},{key:"item.countSku",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.countSku))+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdBy.email)+" ")])]}},{key:"item.solvedBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.solvedBy || '-')+" ")])]}},{key:"item.solvedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.solvedAt ? _vm.getDateFormat(item.solvedAt) : '-')+" ")])]}},{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('div',{staticClass:"transfer-chip"},[_c('v-chip',{class:("warehouse-color-" + (_vm.getLastId(item.fromWarehouse.id))),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.fromWarehouse.name)+" ")]),_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-arrow-right-bold ")]),_c('v-chip',{class:("warehouse-color-" + (_vm.getLastId(item.toWarehouse.id))),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.toWarehouse.name)+" ")])],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.state !== 'solved' && item.state !== 'declined')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"circle":"","total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("In Dispute Form")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"idp-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.states,"item-text":"text","item-value":"value","color":"secondary","label":"สถานะ","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"type":"submit","color":"green darken-1","text":""},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }