import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class IdpReportsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('idp-reports', query)
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.put(`idp-reports/${payload.id}`, payload)
  }
}

export default IdpReportsProvider
