<template>
  <v-container
    class="rounded elevation-3 bg-white h-full"
    fluid>
    <v-row>
      <v-col cols="12">
        <span class="header">In Dispute Report</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-5"
      no-gutters>
      <v-col cols="12">
        <idp-report-header
          v-model="filter"
          :warehouses="warehouses"
          @on-find="find()" />
      </v-col>
      <v-col cols="12">
        <div class="w-full d-flex justify-end align-center">
          <v-btn
            class="mr-3"
            color="secondary"
            :disabled="!totalReports"
            @click="exportDataToCSV()">
            Export ({{ totalReports }})
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="reports"
          hide-default-footer
          no-data-text="ไม่มีข้อมูลในช่วงเวลานี้"
          loading-text="กรุณารอสักครู่...">
          <template #[`item.createdAt`]="{ item }">
            <span>
              {{ getDateFormat(item.createdAt) }}
            </span>
          </template>
          <template #[`item.deliveryNoteId`]="{ item }">
            <span>
              {{ item.deliveryNoteId }}
            </span>
          </template>
          <template #[`item.countSku`]="{ item }">
            <span>
              {{ item.countSku | showNumberFormat() }}
            </span>
          </template>
          <template #[`item.createdBy`]="{ item }">
            <span>
              {{ item.createdBy.email }}
            </span>
          </template>
          <template #[`item.solvedBy`]="{ item }">
            <span>
              {{ item.solvedBy || '-' }}
            </span>
          </template>
          <template #[`item.solvedAt`]="{ item }">
            <span>
              {{ item.solvedAt ? getDateFormat(item.solvedAt) : '-' }}
            </span>
          </template>
          <template #[`item.warehouse`]="{ item }">
            <div class="d-flex justify-end align-center">
              <div class="transfer-chip">
                <v-chip
                  :class="`warehouse-color-${getLastId(item.fromWarehouse.id)}`"
                  x-small>
                  {{ item.fromWarehouse.name }}
                </v-chip>
                <v-icon x-small>
                  mdi-arrow-right-bold
                </v-icon>
                <v-chip
                  :class="`warehouse-color-${getLastId(item.toWarehouse.id)}`"
                  x-small>
                  {{ item.toWarehouse.name }}
                </v-chip>
              </div>
            </div>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="item.state !== 'solved' && item.state !== 'declined'"
              class="mr-2"
              small
              @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPages"
          circle
          total-visible="7" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="showEdit"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">In Dispute Form</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="idp-form"
              @submit.prevent="onSubmit()">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="formData.state"
                    :items="states"
                    item-text="text"
                    item-value="value"
                    color="secondary"
                    label="สถานะ"
                    outlined
                    dense
                    hide-details>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()">
            Close
          </v-btn>
          <v-btn
            type="submit"
            color="green darken-1"
            text
            @click="onSubmit()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import IdpReportsProvider from '@/resources/IdpReportsProvider'
import IdpReportHeader from '../components/IdpReportHeader.vue'

const IdpReportsService = new IdpReportsProvider()

export default {
  components: { IdpReportHeader },
  data () {
    return {
      page: 1,
      perPage: 20,
      totalPages: 0,
      totalReports: 0,
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          sortable: true,
          align: 'center'
        },
        {
          text: 'Delivery Note',
          value: 'deliveryNoteCode',
          sortable: false
        },
        {
          text: 'State',
          value: 'state',
          sortable: false,
          align: 'center',
          cellClass: 'text-capitalize'
        },
        {
          text: 'Created By',
          value: 'createdBy',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Solved By',
          value: 'solvedBy',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Solved At',
          value: 'solvedAt',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Warehouse',
          value: 'warehouse',
          sortable: false,
          align: 'right'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'center'
        }
      ],
      states: [
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Solved',
          value: 'solved'
        },
        {
          text: 'Declined',
          value: 'declined'
        }
      ],
      reports: [],
      filter: {
        dates: [
          this.$dayjs().subtract(6, 'day').toISOString(),
          this.$dayjs().toISOString()
        ],
        warehouse: null,
        state: null,
        search: ''
      },
      selectItem: null,
      formData: {
        deliveryNoteId: null,
        deliveryNoteCode: '',
        fromWarehouse: {
          id: null,
          name: '',
          code: '',
          approvedAt: '',
          approvedBy: ''
        },
        toWarehouse: {
          id: null,
          name: '',
          code: '',
          approvedAt: '',
          approvedBy: ''
        },
        state: 'pending'
      },
      showEdit: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
    }
  },
  watch: {
    page () {
      this.fetchReports()
    }
  },
  mounted () {
    this.fetchReports(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async fetchReports (isClear = false) {
      try {
        this.setLoading({ active: true })

        if (isClear) {
          this.page = 1
        }

        const query = {
          page: this.page,
          limit: this.perPage,
          startDate: this.$dayjs(this.filter.dates[0]).toISOString(),
          endDate: this.$dayjs(this.filter.dates[1]).toISOString(),
          // warehouseId: this.filter.warehouse.id,
          search: this.filter.search,
          state: this.filter.state
        }

        const { data } = await IdpReportsService.getItems(query)

        this.totalPages = data.pages
        this.totalReports = data.total
        this.reports = data.results
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    closeForm () {
      this.showEdit = false
      this.selectItem = null
    },
    async onSubmit () {
      try {
        this.setLoading({ active: true })

        await IdpReportsService.updateItem(this.formData)

        this.setLoading({ active: false })
        this.closeForm()
        this.fetchReports(true)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[UPDATE-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getDateFormat (date) {
      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    find () {
      this.fetchReports(true)
    },
    async exportDataToCSV () {
      try {
        this.setLoading({ active: true })

        const limit = 1000
        let rounds = Math.ceil(this.totalReports / limit)
        rounds = new Array(rounds).fill(0).map((item, index) => index)

        for await (const round of rounds) {
          const query = {
            page: round + 1,
            limit: 1000,
            startDate: this.$dayjs(this.filter.dates[0]).toISOString(),
            endDate: this.$dayjs(this.filter.dates[1]).toISOString(),
            // warehouseId: this.filter.warehouse.id,
            search: this.filter.search,
            state: this.filter.state
          }

          const { data } = await IdpReportsService.getItems(query)

          const reports = data.results

          const items = reports.map((report) => ({
            date: this.getDateFormat(report.createdAt),
            deliveryNote: report.deliveryNoteCode,
            fromWarehouse: report.fromWarehouse.code,
            toWarehouse: report.toWarehouse.code,
            state: report.state,
            createdBy: report.createdBy.email,
            solvedBy: report.solvedBy,
            solvedAt: report.solvedAt ? this.getDateFormat(report.solvedAt) : ''
          }))

          const options = {
            filename: `Idp-Report-${round + 1}`,
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(items)
        }
      } catch (error) {
        console.error('fetchProductsToExport', error)
        this.setSnackbar({
          value: true,
          message: `Error ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    getLastId (id) {
      const idText = id ? id.toString() : '0'
      if (idText) {
        return idText[idText.length - 1]
      }
      return '0'
    },
    editItem (item) {
      this.selectItem = item
      this.formData = { ...item }
      this.showEdit = true
    }
  }
}
</script>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.header {
  font-size: 1.5rem;
  font-weight: bold;
}
.bg-white {
  background-color: white !important;
}
</style>
