<template>
  <div class="search-box-container">
    <form @submit.prevent="commitSearch()">
      <v-text-field
        v-model.trim="searchInput"
        :disabled="disabled"
        append-icon="mdi-magnify"
        :label="placeholder"
        height="40"
        dense
        outlined
        color="secondary"
        hide-details
        @keyup.native.enter="commitSearch()"
        @click:append="commitSearch()" />
      <!-- <div class="ml-2">
        <v-btn
          color="secondary"
          :disabled="disabled"
          type="submit">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div> -->
    </form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    searchInput: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    commitSearch () {
      this.$emit('on-search', this.searchInput)
    }
  }
}
</script>

<style scoped>
.search-box-container form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
